@use 'vars'
@use 'mobile'
body
    transition: all 0.2s linear
    font-family: vars.$font
    margin: 0
    padding: 0
    overflow-x: hidden
    background-color: white
    .container
        @include vars.padding(15%,2%)
        @include vars.flex-column
        align-items: center
        @include mobile.padding(2%,2%)
        width: 100%
    .divider
        box-sizing: border-box
        width: 100%
        height: 2px
        border: 2px solid vars.$red
        margin-top: 5%
        margin-bottom: 5%

    p
        font-size: 1.4em
        font-weight: 300
        margin: 0
        margin-bottom: 1%
    strong
        font-size: 1.4em
        margin: 0
        font-weight: 400
        white-space: normal
    h4 
        font-size: 1.4em
        font-weight: 500
        margin: 0
    h3 
        font-size: 2em
        font-weight: 400
        margin: 0
    h2  
        font-size: 3em
        margin: 0
    h1 
        font-size: 3.6em
        margin: 0
    img 
        user-select: none
    div 
        margin: 0
        padding: 0
    ul 
        display: flex
        flex-direction: column
        margin: 0
        padding: 0
        li 
            text-decoration: none
            margin: 0
            padding: 0
            a 
                text-decoration: none 
                color: vars.$light-blue