@use "./vars"
@use "./mobile"

.app
    @include vars.flex-column
    align-items: center
    width: 100%
    height: 100vh

.redText
    color: vars.$red

.loaderContainer
    @include vars.flex-column
    align-items: center
    height: 100%
    width: 100%
    .loader
        margin: auto
        border: 12px solid whitesmoke
        border-top: 12px solid black
        border-radius: 50%
        height: 50px
        width: 50px
        animation: spin 1s linear infinite

.portalEmployeeTasking
    @extend .portalClientDatabase
    table
        width: 100%
        justify-content: space-evenly
        td
            font-size: 1.4em
            font-weight: 300
        th
            font-size: 1.4em

@keyframes spin
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)

.portalEmployeeDatabase
    @extend .portalClientDatabase

.portalWorkoutManager
    @extend .portalClientDatabase

.portalEComModule
    @extend .portalClientDatabase

.portalWorkoutTracker
    @extend .portalClientDatabase
    .interactiveWorkout
        @include vars.spacing(2%,2%)
        @include vars.padding(3%, 3%)
        @include vars.flex-column
        justify-content: space-between
        border: 1px solid black
        border-radius: 20px
        width: 100%
        @media (max-width: vars.$breakpoint_tablet)
            align-items: center
        .top
            @include vars.flex-row
            align-items: center
            justify-content: space-between
            @media (max-width: vars.$breakpoint_tablet)
                @include vars.flex-column
                flex-direction: column-reverse
            p
                margin: 0
                padding: 0
        .middle
            @media (max-width: vars.$breakpoint_tablet)
                @include vars.flex-row
        .bottom
            @extend .top
            margin-top: 2%
            @media (max-width: vars.$breakpoint_tablet)
                @include vars.flex-row
            button
                width: 28vw
                margin: 1%

.portalWorkoutTutorialsModule
    @extend .portalClientDatabase
    .preWrapper
        width: 100
        pre
            width: 100%
            font-size: 1.6em
            font-weight: 300

.videoContainer
    position: relative
    padding-bottom: 56.25%
    width: 100%
    height: 0
    iframe
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        border: 0

.portalWorkoutPlanModule
    @extend .portalWorkoutPlanningModule
    width: 100%
    .display
        @include vars.flex-column
        width: 100%
        .workoutDay
            width: 100%
        .workoutCard
            @include vars.flex-column
            border: 1px solid black
            border-radius: 5px
            padding: 2%
            margin-bottom: 1.5%
            margin-top: 1.5%
            .label
                input
                    width: 100%
                    color: black !important
                label
                    font-size: 1.4em

.portalNutritionPlanModule
    @extend .portalNutritionPlanningModule
    width: 100%
    textarea
        color: black
        z-index: 5
        overflow-y: scroll
        &>*
            color: black
            z-index: -1
    pre
        font-size: 1.4em
    .mealList
        .label
            @include vars.flex-row
        label
            font-size: 1.4em
        p
            font-size: 1.4em
            margin-left: 3%
        .mealCard
            width: 25%

.portalHomeModule
    @extend .portalClientDatabase
    .cards
        @include vars.flex-row
        flex-wrap: wrap
        justify-content: flex-start
        width: 100%
        .card
            @include vars.flex-column
            transition: all 0.2s ease
            align-items: center
            width: 20%
            margin: 2%
            padding: 2%
            border: 1px solid vars.$grey
            background-color: vars.$grey
            border-radius: 5px
            @media (max-width: vars.$breakpoint_tablet)
                width: 100%
            img
                width: 100%
                height: auto
                margin-bottom: 2%
                @media (max-width: vars.$breakpoint_tablet)
                    width: 50%
            &:hover
                cursor: pointer
                background-color: vars.$red
                color: white

.portalNewCheckinsModule
    @extend .portalClientDatabase
    .checkinForm
        box-sizing: border-box
        @include vars.padding(3%,3%)
        background-color: whitesmoke
        border: 1px solid black
        border-radius: 5px
        margin-right: 3%
        width: 100vw
        @media (max-width: vars.$breakpoint_tablet)
            width: 100%
            margin-top: 3%
            margin-bottom: 3%
        strong
            font-size: 1.0em
        button
            margin-top: 6%
            width: 100%
        .pad
            margin-top: 5%
    .forms
        @include vars.flex-row
        width: 100%
        align-items: stretch
        flex-wrap: wrap
        margin-bottom: 3%
        margin-top: 3%
        .checkinForm
            @include vars.padding(3%,3%)
            background-color: whitesmoke
            border: 1px solid black
            border-radius: 5px
            margin-right: 3%
            width: 100%
            @media (max-width: vars.$breakpoint_tablet)
                width: 100%
            strong
                font-size: 1.0em
                font-weight: 600
            button
                margin-top: 6%
                width: 100%
            img
                width: 100%
                height: auto
            .photoControl
                color: vars.$red
                &:hover
                    cursor: pointer

.portalOldCheckinsModule
    @extend .portalClientDatabase
    .forms
        @include vars.flex-row
        width: 100%
        align-items: stretch
        flex-wrap: wrap
        margin-bottom: 3%
        margin-top: 3%
    .checkinForm
        @include vars.padding(3%,3%)
        background-color: whitesmoke
        border: 1px solid black
        border-radius: 5px
        margin-right: 3%
        width: 30%
        @media (max-width: vars.$breakpoint_tablet)
            width: 100%
        strong
            font-size: 1.0em
        button
            margin-top: 6%
            width: 100%
        .pad
            margin-top: 2% !important
            margin-bottom: 2% !important

.portalCheckinsModule
    @extend .portalClientDatabase
    strong
        margin-bottom: 1%
    input[type="file"]
        box-sizing: border-box
        margin-bottom: 20px
        padding: 2%
        padding-bottom: 3%
        @media (max-width: vars.$breakpoint_tablet)
            padding-bottom: 8%

.portalSchedulingManagerModule
    @extend .portalClientDatabase

.portalSchedulingModule
    @extend .portalClientDatabase

.display
    @include vars.flex-row
    align-items: flex-start
    width: 100%
    flex-wrap: wrap

.portalWorkoutPlanningModule
    @extend .portalClientDatabase
    .display
        @include vars.flex-column
        width: 66%
        @media (max-width: vars.$breakpoint_tablet)
            width: 100%
        .workoutDay
            transition: all 0.2s ease
            box-sizing: border-box
            width: 100%
            border: 1px solid black
            border-radius: 5px
            margin-bottom: 1%
            overflow: hidden
            input
                width: 100%
                color: black !important
            .header
                @include vars.flex-row
                @include vars.padding(2%,0.5%)
                align-items: center
                justify-content: space-between
                width: 100%
                &:hover
                    cursor: pointer
                img
                    width: 25px
                    height: auto
            .content
                transition: all 0.2s ease
                @include vars.flex-column
                @include vars.padding(2%, 1%)
                background-color: vars.$grey
                color: black
                overflow: hidden
                visibility: hidden
                opacity: 0
                height: 0px
                img
                    align-self: center
                    width: 75px
                    height: auto
                    &:hover
                        cursor: pointer
            .visible
                box-sizing: border-box
                visibility: visible
                opacity: 1
                height: 100%
            .dayControls
                @include vars.flex-column
                align-items: center
                button
                    width: 100%
            .workoutCard
                @include vars.flex-column
                border: 1px solid black
                border-radius: 5px
                padding: 1%
                margin-bottom: 2%
                background-color: white
                input
                    width: 100%
                    @media (max-width: vars.$breakpoint_tablet)
                        color: #bdbdbd

.portalNutritionPlanningModule
    @extend .portalClientDatabase
    width: 100%
    input
        width: 100% !important
        @media (max-width: vars.$breakpoint_tablet)
            color: #bdbdbd
    textarea
        width: 100% !important
    .label
        width: 100% !important
        @include vars.flex-column
    .top
        @include vars.flex-row
        @include mobile.flex-column
        width: 100%
        .section
            @include vars.flex-column
            width: 25%
            @media (max-width: vars.$breakpoint_tablet)
                width: 100%
                margin: 1%
            .label
                @include vars.flex-row
            label
                font-size: 1.4em
            p
                font-size: 1.4em
                margin-left: 3%

    .mealList
        @include vars.flex-row
        flex-wrap: wrap
        width: 100%
        .mealCard
            border: 1px solid black
            border-radius: 5px
            padding: 1%
            margin: 1%
            @media (max-width: vars.$breakpoint_tablet)
                width: 100%

.portalClientNotes
    @extend .portalClientDatabase
    textarea
        height: 60vh

.portalClientDatabase
    @include vars.flex-column
    align-items: flex-start
    width: 100%
    select
        box-sizing: border-box
        width: 50%
        padding: 0.8%
        margin-bottom: 1%
        @media (max-width: vars.$breakpoint_tablet)
            padding: 6%
            width: 100%
            margin-bottom: 3%
    strong
        margin-right: 10px
        font-weight: bold
    h3
        margin-top: 2%
        margin-bottom: 2%
        color: black
        font-weight: 600
    input
        box-sizing: border-box
        padding: 0.8%
        margin-bottom: 1%
        width: 50%
        font-size: 1.4em
        font-weight: 300
        @media (max-width: vars.$breakpoint_tablet)
            width: 100%
        &::placeholder
            color: vars.$dark-grey
            @media (max-width: vars.$breakpoint_tablet)
                color: #bdbdbd
    textarea
        box-sizing: border-box
        padding: 0.8%
        margin-bottom: 1%
        width: 50%
        min-height: 150px
        font-size: 1.4em
        font-weight: 300
        resize: none
        white-space: pre-wrap
        @media (max-width: vars.$breakpoint_tablet)
            width: 100%
        &::placeholder
            color: vars.$dark-grey
            font-size: 1.2em
            font-weight: 300
    button
        transition: all 0.1s ease
        box-sizing: border-box
        padding: 20px
        margin: 0
        margin-bottom: 1%
        width: 50%
        white-space: nowrap
        border: none
        border-radius: 10px
        background-color: black
        color: white
        font-size: 16px
        font-weight: 300
        @media (max-width: vars.$breakpoint_tablet)
            width: 100%
        &:hover
            background-color: vars.$red
            cursor: pointer
            color: white

.portalAccountModule
    @include vars.flex-column
    align-items: flex-start
    width: 100%
    height: 100%
    strong
        white-space: nowrap
        margin-right: 10px
    h3
        margin-top: 2%
        margin-bottom: 2%
    input
        box-sizing: border-box
        padding: 0.8%
        margin-bottom: 1%
        width: 50%
        font-size: 1.4em
        font-weight: 300
        @media (max-width: vars.$breakpoint_tablet)
            width: 100%
        &::placeholder
            color: vars.$dark-grey
            @media (max-width: vars.$breakpoint_tablet)
                color: #bdbdbd
    button
        transition: all 0.1s ease
        box-sizing: border-box
        padding: 20px
        margin: 0
        margin-bottom: 1%
        width: 50%
        background-color: black
        color: white
        border: none
        @media (max-width: vars.$breakpoint_tablet)
            width: 100%
            margin-top: 3%
            margin-bottom: 3%
            white-space: nowrap
            border: none
            border-radius: 0px
            background-color: black
            color: white
            font-size: 16px
            font-weight: 300
        &:hover
            background-color: vars.$red
            cursor: pointer
            color: white

.rowWrapper
    @include vars.flex-row

.clientLogin
    @include vars.flex-column
    align-items: center
    width: 100vw
    height: 100vh
    background-color: black
    .window
        @include vars.flex-column
        @include mobile.padding(4%,2%)
        box-sizing: border-box
        align-items: center
        justify-content: center
        width: 60%
        margin-top: auto
        margin-bottom: auto
        padding: 5%
        border-radius: 25px
        background-color: white
        @media (max-width: vars.$breakpoint_tablet)
            width: 90%
            margin-left: auto
            margin-right: auto
        h1
            font-weight: 300
            margin: 0
            margin-bottom: 2%
        img
            width: 30%
            height: auto
            margin-bottom: 6%
            @media (max-width: vars.$breakpoint_tablet)
                width: 50%
        input
            font-size: 1.4em
            font-weight: 300
            margin: 0
            margin-bottom: 1.5%
            margin-top: 1.5%
            padding: 0.8%
            width: 50%
            border-radius: 5px
            border-color: black
            @media (max-width: vars.$breakpoint_tablet)
                width: 100%
            &::placeholder
                color: vars.$dark-grey
                @media (max-width: vars.$breakpoint_tablet)
                    color: #bdbdbd
        a
            font-size: 1.4em
            transition: 0.2s all ease
            text-decoration: none
            color: black
            margin-bottom: 1%
            &:hover
                cursor: pointer
                color: vars.$red
        p
            font-size: 1.4em
            font-weight: 500
            transition: 0.2s all ease
            text-decoration: none
            color: black
            margin-bottom: 1%
            &:hover
                cursor: pointer
                color: vars.$red
        button
            transition: all 0.1s ease
            box-sizing: border-box
            padding: 20px
            margin: 0
            margin-top: 1.5%
            margin-bottom: 1.5%
            width: 52%
            white-space: nowrap
            border: none
            border-radius: 0px
            background-color: black
            color: white
            font-size: 16px
            font-weight: 300
            &:hover
                background-color: vars.$red
                cursor: pointer
                color: white

.clientMain
    @include vars.flex-row
    align-items: flex-start
    width: 100%
    height: 100vh
    overflow-x: hidden
    overflow-y: hidden
    aside
        @include vars.flex-column
        @include mobile.noshow
        align-items: flex-end
        width: 15%
        height: 93%
        overflow-x: hidden
        overflow-y: auto
        border-right: 1px solid black
        background-color: vars.$grey
        a
            @include vars.padding(2%,2%)
            @include vars.flex-column
            align-items: center
            width: 100%
            text-decoration: none
            color: black
            padding: 3%
            &:hover
                background-color: vars.$red
                cursor: pointer
                color: white
        label
            box-sizing: border-box
            background-color: black
            color: white
            width: 100%
            padding: 3%
            font-size: 1.4em
            text-align: center
    section
        @include vars.flex-column
        @include vars.padding(3%,3%)
        height: calc( 100% - 80px )
        overflow-x: hidden
        align-items: flex-start
        width: 85%
        overflow-y: auto
        @media (max-width: vars.$breakpoint_tablet)
            width: 100vw
            overflow-x: hidden
            height: 78vh

.clientHeader
    @include vars.flex-column
    @include vars.padding(3%,1%)
    align-items: center
    width: 100%
    background-color: black
    .desktop
        @include vars.flex-row
        @include mobile.noshow
        align-items: center
        justify-content: space-between
        width: 100%
        img
            box-sizing: border-box
            width: 10% !important
            height: auto !important
            margin: 0
        button
            transition: all 0.1s ease
            box-sizing: border-box
            padding: 20px
            margin: 0
            white-space: nowrap
            border: none
            border-radius: 0px
            background-color: white
            color: black
            font-size: 16px
            font-weight: 300
            &:hover
                background-color: vars.$red
                cursor: pointer
                color: white
    .mobile
        @include vars.flex-column
        display: none
        @include mobile.show
        align-items: center
        width: 100%
        img
            box-sizing: border-box
            width: 40%
            height: auto
            margin: 3%

.mobileSlideOut
    @include vars.flex-column
    @include vars.padding(3%,3%)
    padding-bottom: 8%
    visibility: hidden
    transition: all 0.5s ease
    position: absolute
    top: -100vh
    left: 0
    width: 100%
    height: 100%
    overflow-x: hidden
    overflow-y: scroll
    align-items: flex-start
    justify-content: left
    background-color: vars.$dark-grey
    opacity: 0.98
    z-index: 1000
    label
        color: vars.$red
        font-size: 1.8em
        margin-bottom: 3%
        margin-top: 3%
    a
        text-decoration: none
        font-size: 1.6em
        color: white
        margin-top: 1%
        margin-bottom: 1%
    button
        transition: all 0.1s ease
        box-sizing: border-box
        padding: 20px
        margin: 0
        white-space: nowrap
        border: none
        border-radius: 0px
        background-color: white
        color: black
        font-size: 16px
        font-weight: 300
        width: 100%
        margin-bottom: 6%
        &:hover
            background-color: vars.$red
            cursor: pointer
            color: white

.menuOpen
    visibility: visible
    top: 0

.clientFooter
    position: fixed
    bottom: 0
    left: 0
    @include vars.flex-column
    @include vars.padding(3%,1%)
    align-items: center
    width: 100%
    background-color: black
    @media (max-width: vars.$breakpoint_tablet)
        height: 100px
        border-top-right-radius: 20px
        border-top-left-radius: 20px

    .mobile
        width: 100%
        display: none
        @media (max-width: vars.$breakpoint_tablet)
            display: block
            @include vars.flex-row
            align-items: center
            justify-content: space-between
            flex-wrap: nowrap
            img
                width: 10%
                height: auto
                margin: 3%
    .desktop
        @include vars.flex-row
        align-items: center
        justify-content: space-between
        width: 100%
        height: 6%
        a
            transition: all 0.2s ease
            text-decoration: none
            color: white
            &:hover
                cursor: pointer
                color: vars.$red
        @media (max-width: vars.$breakpoint_tablet)
            display: none

.timer
    @include vars.flex-column
    width: 100%
    align-items: center
    .time
        @include vars.flex-row
        align-items: center
        justify-content: center
        width: 100%
        font-size: 3em
        font-weight: 600
        margin-bottom: 3%
    .buttons
        @include vars.flex-column
        width: 100%
        button
            align-self: center
            width: 40%
    .inputs
        @include vars.flex-column
        align-items: center
        width: 100%
        label 
            font-size: 1.5em
            font-weight: 400
        select
            width: 40%
            padding: 1%
            font-size: 1.5em
