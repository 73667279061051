@use 'vars'

@mixin flex-column
    @media (max-width: vars.$breakpoint_tablet)
        box-sizing: border-box
        display: flex
        flex-direction: column
@mixin flex-row
    @media (max-width: vars.$breakpoint_tablet)
        box-sizing: border-box
        display: flex
        flex-direction: row
@mixin padding($gutters,$general)
    @media (max-width: vars.$breakpoint_tablet)
        padding: $general
        padding-left: $gutters
        padding-right: $gutters

@mixin margin($gutters,$general)
    @media (max-width: vars.$breakpoint_tablet)
        margin: $general
        margin-left: $gutters
        margin-right: $gutters
        
@mixin header-font-fix
    @media (max-width: vars.$breakpoint_tablet)
        h1
            font-size: 3em 
        h2 
            font-size: 2em

@mixin noshow
    @media (max-width: vars.$breakpoint_tablet)
        display: none
@mixin show
    @media (max-width: vars.$breakpoint_tablet)
        display: flex


            


